<template>
  <div ref="minicontainer">
    <v-btn-toggle
      v-model="selected"
      dense
      background-color="white"
      multiple
      dark
      class="loggingButtons d-block"
      data-type="loggingButtons"
      :style="'min-width:' + (parseInt(containerWidth) + 1) + 'px'"
    >
      <weightedButtonsMiniButton
        v-for="(info, lineIndex) in items"
        :key="lineIndex"
        :lineIndex="lineIndex"
        :width="calculatedWidth(info)"
        :info="info"
        :minVal="minVal"
        :selected="selected"
      ></weightedButtonsMiniButton>
    </v-btn-toggle>
  </div>
</template>
<script>
import weightedButtonsMiniButton from "@/commonComponents/weightedButtonsMiniButton.vue";
export default {
  components: { weightedButtonsMiniButton },
  props: ["items", "totalItems"],
  data() {
    return {
      containerWidth: 0,
      selected: [],
      window: {
        width: 0,
        height: 0,
      },
    };
  },

  watch: {
    selected: function (val) {
      this.$emit("selectedItem", val);
    },
  },
  mounted() {
    this.getContainerWidth();
  },
  created() {
    this.getContainerWidth();
    window.addEventListener("resize", this.getContainerWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.getContainerWidth);
  },
  computed: {
    minVal() {
      let minVal = 20;
      if (this.originalSum < 20) {
        minVal = 1;
      }
      return minVal;
    },
    // sum() {
    //   let sum = 0;
    //   Object.values(this.items).forEach((info) => {
    //     let width = (info.count / this.totalItems) * 100;

    //     if (width < 10) {
    //       width = 10;
    //     }
    //     sum += width;
    //   });
    //   return sum;
    // },
    originalSum() {
      let sum = 0;
      Object.values(this.items).forEach((info) => {
        let count = info.count;
        sum += parseInt(count);
      });
      return sum;
    },
    sum() {
      let sum = 0;
      Object.values(this.items).forEach((info) => {
        let count = info.count;
        if (count < this.minVal) {
          count = this.minVal;
        }
        let width = (count / this.totalItems) * this.containerWidth;

        // if (width < this.minVal) {
        //   width = this.minVal;
        // }
        sum += width;
      });
      return sum;
    },
  },
  methods: {
    getContainerWidth() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 120;
      // let width = 0;
      this.containerWidth = 0;
      if (typeof this.$refs.minicontainer != "undefined") {
        this.containerWidth = this.$refs.minicontainer.clientWidth;
      }
      // this.containerWidth = width;
      if (this.$vuetify.breakpoint.xsOnly) {
        this.containerWidth = this.window.width - 120;
      } else {
        if (this.containerWidth > this.window.width - 450) {
          this.containerWidth = this.window.width - 450;
        }

        if (this.window.width > 1200) {
          if (this.containerWidth > this.window.width - 700) {
            this.containerWidth = this.window.width - 700;
          }
        }
        if (this.window.width / 3 > this.containerWidth) {
          let x = this.window.width;
          this.containerWidth = 0.704 * x - 270;
        }
      }
    },
    calculatedWidth(info) {
      let width;
      if (this.totalItems == 0) {
        width = this.containerWidth / Object.keys(this.items).length;
      } else {
        //width = (info.count * 100) / this.totalItems + "%";
        let count = info.count;
        if (count < this.minVal) {
          count = this.minVal;
        }
        width = (count / this.totalItems) * this.containerWidth;
        width = (width * this.containerWidth) / this.sum;

        // if (width < this.minVal) {
        //   width = this.minVal;
        // }
      }
      // width += "px";

      return width;
    },
    // calculatedWidth(info) {
    //   let width;
    //   if (this.totalItems == 0) {
    //     width = 100 / Object.keys(this.items).length;
    //   } else {
    //     //width = (info.count * 100) / this.totalItems + "%";
    //     width = (info.count / this.totalItems) * 100;

    //     width = (width * 100) / this.sum;
    //     if (width < 10) {
    //       width = 10;
    //     }
    //   }
    //   width += "%";

    //   return width;
    // },
  },
};
</script>
<style>
.v-application--is-ltr .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
  border-left: 2px solid white !important;
}
</style>
