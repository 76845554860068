<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="pa-0"
        :min-width="width"
        :max-width="width"
        width="100%"
        :key="lineIndex"
        :color="selected.includes(lineIndex) ? info.color : info.color"
        :value="lineIndex"
        small
        v-bind="attrs"
          v-on="on"
        style="height:25px;border-color:white!important;"

      >
        <span v-if="width > minVal">{{ info.count }}</span>
      </v-btn>
    </template>
    <span>{{ info.count }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: ["width", "info", "lineIndex", "selected", "minVal"],
};
</script>


